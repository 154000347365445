import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  Typography,
  Box,
  useMediaQuery,
  CircularProgress,
  Breadcrumbs,
  TextField,
  MenuItem,
  Slider,
} from '@mui/material';
import ProductCard from './ProductCard';

const Products = () => {
  const [sofas, setSofas] = useState([]);
  const [chairs, setChairs] = useState([]);
  const [category, setCategory] = useState('All');
  const [priceRange, setPriceRange] = useState([499, 7999]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sofasError, setSofasError] = useState(null);
  const [chairsError, setChairsError] = useState(null);
  const isMobile = useMediaQuery('(max-width:600px)');

  const baseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001/api';

  useEffect(() => {
    if (!baseUrl) {
      console.error("REACT_APP_API_BASE_URL is not defined in .env file");
    }
  }, [baseUrl]);

  const fetchProducts = async () => {
    try {
      const sofaResponse = await axios.get(`${baseUrl}/products/Sofa`);
      setSofas(sofaResponse.data);
    } catch (err) {
      setSofasError(`Error fetching sofas: ${err.message}`);
    }

    try {
      const chairsResponse = await axios.get(`${baseUrl}/products/Chairs`);
      setChairs(chairsResponse.data);
    } catch (err) {
      setChairsError(`Error fetching chairs: ${err.message}`);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchProducts();
    setLoading(false);
  }, [baseUrl]);

  const filteredProducts = [...sofas, ...chairs].filter(product =>
    (category === 'All' || product.category === category) &&
    product.price >= priceRange[0] && product.price <= priceRange[1]
  );

  if (loading) return <CircularProgress />;
  if (sofasError) return <Typography color="error">{sofasError}</Typography>;
  if (chairsError) return <Typography color="error">{chairsError}</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 3, mb: 3, fontSize: '1rem', color: 'primary.main' }}>
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>Home</Link>
        <Typography color="text.primary">Products</Typography>
      </Breadcrumbs>

      <Typography variant="h4" textAlign="center">
        {category === 'All' ? 'Our Products' : `${category} Products`}
      </Typography>
      {/* Category Filter */}
      <Box display="flex" justifyContent="space-between" mb={3}>
        <TextField
          label="Category"
          select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Sofa">Sofas</MenuItem>
          <MenuItem value="Chairs">Chairs</MenuItem>
        </TextField>

        {/* Price Filter */}
        <Box>
          <Typography>Price: ${priceRange[0]} - ${priceRange[1]}</Typography>
          <Slider
            value={priceRange}
            onChange={(e, newValue) => setPriceRange(newValue)}
            min={499}
            max={7999}
            valueLabelDisplay="auto"
          />
        </Box>
      </Box>

      {/* Products List */}
      <Box display="flex" flexWrap="wrap">
        {filteredProducts.map((product) => (
          <ProductCard key={product._id} product={product} />
        ))}
      </Box>
    </Container>
  );
};

export default Products;
